:root {
  --primary-color: #007bff;
  --secondary-color: hsla(169.6969697, 79.2%, 24.50980392%, 1);
  --background-dark-color: hsla(0, 0% , 0%, 0.8);
  --background-dark-color-2: hsla(0, 0% , 0%, 0.1);
  --border-color: #2e344e;
  --background-light-color: hsla(0,0% ,100%, 0.5);
  --background-light-color-2: rgb(247, 246, 246);
  --white-color: #FFF;
  --font-light-color: #a4acc4;
  --font-dark-color: #313131;
  --font-dark-color-2: #151515;
  --sidebar-dark-color: #191d2b;
}

/* semantic tags */

body {
  display: flex;
  flex-direction: column;
}

h1 {
  text-transform: uppercase;
  font-size: 2rem;
  color: var(--font-dark-color);
}

/* single attribute classes */

.center {
  text-align: center;
}

.c-1 {
  grid-template-columns: 1fr;
}

.c-2 {
  grid-template-columns: 1fr 1fr;
}

.c-3 {
  grid-template-columns: 1fr 1fr 1fr;
}

.d-inline {
  display: inline !important;
}

.flex {
  display: flex;
}

.m-0 {
  margin: 0;
}

.mb-1 {
  margin-bottom: 1rem;
}

.mb-2 {
  margin-bottom: 2rem;
}

.mt-1 {
  margin-top: 1rem;
}

.mr-1 {
  margin-right: 1rem;
}

.mr-2 {
  margin-right: 2rem;
}

.mx-1 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.ml-1 {
  margin-left: 1rem;
}

.mx-a {
  margin-left: auto;
  margin-right: auto;
}

.pr-1 {
  padding-right: 1rem;
}

.pl-1 {
  padding-left: 1rem;
}

.s-b {
  justify-content: space-between;
}

.w-a {
  width: auto;
}

.w-50 {
  width: 50% !important;
}

/* classes */

.contact-info {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  color: var(--sidebar-dark-color);
  width: 100%;
  box-sizing: border-box;
}

.contact-info ul {
  padding: 0;
}

.contact-info li {
  list-style: none;
  display: inline;
  margin-right: 3rem;
}

.contact-info li:not(:last-child):before {
  margin-right: 4rem;
}

.form-section {
  padding: 1rem;
  color: var(--sidebar-dark-color);
  width: 50%;
  box-sizing: border-box;
  margin: 0 auto;
}

.form-section fieldset {
  border: 1px solid var(--sidebar-dark-color);
  border-radius: 8px;
  padding: 1rem;
  width: 100%;
  margin: 0 auto;
}

.form-row {
  display: grid;
  grid-gap: 10px;
  margin-bottom: 15px;
}

.form-row label {
  text-align: right;
  padding-right: 10px;
}

.form-row input,
.form-row textarea {
  width: 100%;
  padding: 0.5rem;
  box-sizing: border-box;
  border-radius: 0.5rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-size:1rem;
}

.mobile-only {
  display: none;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: var(--background-dark-color);
  color: white;
  z-index: 1;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  box-sizing: border-box;
  height: 85px;
}

.hamburger {
  display: none;
}

#menu {
  display: flex;
  list-style: none;
  margin-left: auto;
}

.nav-phone {
  font-size: .7rem;
}  

#menuToggle {
  display: flex;
  flex-direction: row;
  position: relative;
  top: 0;
  left: 0;
  z-index: 1;
  -webkit-user-select: none;
  user-select: none;
}

#menu li {
  margin: 0 1rem;
  cursor: pointer;
}

#menuToggle input {
  display: none;
}

#menu a {
  color: white;
  text-decoration: none;
  transition: color 0.3s ease;
}

#menu a:hover {
  color: #ddd;
}

.apply-now {
  margin-top: 100px;
}

.apply-now h1 {
  width: 100%;
  margin: 0 auto;
  font-size: 2rem;
  text-align: center;
  margin: 0 auto 2rem;
}

.apply-steps-grid {
  box-sizing: border-box;
  max-width: 1200px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  padding: 2rem 2rem 4rem;
  margin: 0 auto 5rem;
}

.apply-step {
  display: inline;
  text-align: center;
  width: 15vw;
  height: 15vh;
  margin: 0 auto;
}

.apply-step-number {
  width: 100%;
  height: 100%;
}

.apply-step-number img {
  width: auto;
  height: 100%; 
}

.apply-step-text {
  width: auto;
  margin: 1rem auto;
  font-weight: bold;
}

.banner {
  background-color: var(--secondary-color);
  box-sizing: border-box;
  color: white;
  font-size: 1rem;
  text-align: center;
  text-transform: capitalize;
  padding: 2rem 0;
  position: relative;
  display: flex;
}

.banner h1 {
  font-size: 1.2rem;
  margin: 0;
  margin: auto;
  color: white;
}

.bg-grey {
  background-color: var(--background-light-color-2);
  padding: 2rem
}

.btn {
  font-size: 1.2rem;
  cursor: pointer;
  border: none;
  background-color: var(--secondary-color);
  color: white;
  padding: 1.2rem 2rem;
  text-transform: uppercase;
  border-radius: 8px;
  min-width: 150px;
}

.btn.inverted {
  border: thin solid var(--secondary-color);
  background-color: white;
  color: var(--secondary-color);
}

.btn.wide {
  width: auto;
}

input::file-selector-button {
  background: white;
  color: var(--secondary-color);
  padding: 1rem 2rem;
  border-radius: 8px;
  cursor: pointer;
}

.footer {
  background-color: var(--background-dark-color);
  color: white;
  padding: 2rem;
  text-align: center;
  width: 100%;
  box-sizing: border-box;
}

.footer li {
  list-style: none;
  display: inline;
}

.footer-logo {
  display: block;
  cursor: pointer;
}

.footer-logo img {
  width: auto;
  max-width: 100%;
  max-height: 57px;
}

.footer li:not(:last-child):after {
  content: " | ";
}

.industries-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.industry-box {
  position: relative;
  width: 100%;
  padding-top: 75%; /* 4:3 Aspect Ratio */
  overflow: hidden;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.industry-box img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.industry-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
  padding: 10px;
  border-radius: 8px;
}

.header-logo {
  display: block;
  cursor: pointer;
}

.header-logo img {
  width: auto;
  max-width: 100%;
  max-height: 57px;
}

.hero-block {
  width: 100%;
  height: 60vh;
}

.hero-block img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
}

.industries-bg {
  background-position: center bottom;
  background-size: cover;
  background-repeat: no-repeat;
  filter: brightness(50%);
  padding: 5rem;
}

.inner-highlight {
  background-color: var(--background-light-color);
  width: 100%;
  padding: 1rem;
  border-radius: 8px;
  margin: 1rem 0;
}

/* modal */
.error-message {
  color: red;
  display: none;
}

.error-message.active {
  display: block;
}

.outer-modal {
  display: none;
  position: fixed;
  z-index: 10;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  overflow: auto;
  background-color: rgba(0,0,0,0.4);
}

.outer-modal.active {
  display: block;
}

.inner-modal {
  background-color: #fefefe;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
}

.modal-close {
  background-color: white;
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  border: none;
}

.modal-close:hover,
.modal-close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.modal-content {
  padding: 20px;
}


/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.pointer {
  cursor: pointer;
}

.stepper-wrapper {
  margin-top: auto;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.stepper-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;

  @media (max-width: 768px) {
    font-size: 12px;
  }
}

.stepper-item::before {
  position: absolute;
  content: "";
  border-bottom: 2px solid #ccc;
  width: 100%;
  top: 20px;
  left: -50%;
  z-index: 2;
}

.stepper-item::after {
  position: absolute;
  content: "";
  border-bottom: 2px solid #ccc;
  width: 100%;
  top: 20px;
  left: 50%;
  z-index: 2;
}

.stepper-item .step-counter {
  position: relative;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #ccc;
  margin-bottom: 6px;
}

.stepper-item.active {
  font-weight: bold;
}

.stepper-item.completed .step-counter {
  background-color: var(--secondary-color);
}

.stepper-item.completed::after {
  position: absolute;
  content: "";
  border-bottom: 2px solid var(--secondary-color);
  width: 100%;
  top: 20px;
  left: 50%;
  z-index: 3;
}

.stepper-item:first-child::before {
  content: none;
}
.stepper-item:last-child::after {
  content: none;
}

.text-block {
  text-transform: none;
  font-size: 1.2rem;
}

.testimonials-block {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  margin: 1rem;
  padding: 1rem;
}

.testimonial-inner {
  height: auto;
  width: 95%;
  padding: 1rem;
  border: thin solid var(--background-dark-color);
  border-radius: 1rem;
}

.testimonials-block p {
  font-size: 1.1rem;
  text-transform: none;
  color: var(--font-dark-color-2);
}

.video-block {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  min-height: 66vh;
  position: relative;
}

.video-background {
  display: flex;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
}

.video-iframe {
  overflow: hidden;
  z-index: -1;
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.section {
  text-align: center;
  box-sizing: border-box;
  padding: 2rem 0
}

.two-column-with-image {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  width: 100%;
  height: 60vh;
  margin: 0 auto;
  background-color: rgba(255, 255, 255, 0.438);
  filter: brightness(100%);
  border-radius: 2rem;
}

.two-column-with-image > * {
  width: auto;
  margin: 4rem auto;
}

.ul-no-bullets {
  list-style-type: none;
  padding: 0;
}

.ul-no-bullets li {
  padding: 0 0 1rem;
  display: block;
}

/* media queries */

@media screen and (min-width: 480px) {
  .App {
    padding: 0;
  }  
  
  .btn.wide {
    width: 350px;
  }  
}

@media screen and (max-width: 480px) {
  .App {
    padding: 0;
  }  
  .btn {
    margin-bottom: 1rem;
  }
}

@media screen and (min-width: 768px) {

}

@media screen and (max-width: 768px) {
  .App {
    padding: 0;
  }    

  .desktop-only {
    display: none;
  }

  .contact-info ul {
    text-align: center;
    display: block;
    margin: 0 auto 1rem;
    width: 100%;
  }

  .contact-info li {
    display: block;
    margin: 0 auto 1rem;
    width: 100%;
  }

  .form-row {
    display: flex;
    flex-direction: column;
  }

  .form-section {
    width: 100%;
  }

  .mobile-only {
    display: block;
  }

  .navbar {
    justify-content: space-between;
    position: relative;
    z-index: 100;
  }
      
  #menuToggle {
    display: flex;
    flex-direction: column;
    position: relative;
    top: 0;
    left: 0;
    z-index: 101;
    -webkit-user-select: none;
    user-select: none;
  }

  #menuToggle input {
    display: flex;
    width: 40px;
    height: 32px;
    position: absolute;
    cursor: pointer;
    opacity: 0;
    z-index: 102;
  }

  #menuToggle span {
    display: flex;
    width: 29px;
    height: 2px;
    margin-bottom: 5px;
    position: relative;
    background: #ffffff;
    border-radius: 3px;
    z-index: 101;
    transform-origin: 5px 0px;
    transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0), background 0.5s cubic-bezier(0.77,0.2,0.05,1.0), opacity 0.55s ease;
  }

  #menuToggle span:first-child
  {
    transform-origin: 0% 0%;
  }

  #menuToggle span:nth-last-child(2)
  {
    transform-origin: 0% 100%;
  }

  #menuToggle input:checked ~ span
  {
    opacity: 1;
    transform: rotate(45deg) translate(-3px, -1px);
    background: #36383F;
  }
  #menuToggle input:checked ~ span:nth-last-child(3)
  {
    opacity: 0;
    transform: rotate(0deg) scale(0.2, 0.2);
  }

  #menuToggle input:checked ~ span:nth-last-child(2)
  {
    transform: rotate(-45deg) translate(0, -1px);
  }

  #menu {
    display: none;
    position: absolute;
    right: -25px;
    top: -25px;
    width: 75vw;
    height: auto;
    box-shadow: 0 0 10px #85888C;
    margin: 0;
    padding: 50px;
    padding-top: 125px;
    background-color: #F5F6FA;
    -webkit-font-smoothing: antialiased;
    transform-origin: 100% 0%;
    transform: translate( 100%, 0);
    transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
  }

  #menu a {
    color: var(--background-dark-color);
    font-size: 2rem;
  }

  #menu ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  #menu li {
    padding: 10px 0;
    transition-delay: 2s;
    width: 90%;
    margin: 1rem auto 2rem;
    color: var(--background-dark-color);
    font-size: 2rem;
    display: block;
  }

  #menuToggle input:checked ~ ul
  {
    display: block;
    transform: none;
  }

  .hamburger {
    justify-content: flex-end;
    display: flex;
    flex-direction: column;
    margin-left: auto;
  }

  .industries-grid {
    grid-template-columns: 1fr;
    margin: 1rem 2rem;
  }

  .bar {
    width: 35px;
    height: 2px;
    background-color: var(--background-light-color);
    margin: 4px 0;
    transition: all 0.3s ease;
  }

  /* Hamburger menu open state */
  .bar.open:nth-child(1) {
    transform: rotate(45deg) translate(5px, 5px);
  }
  
  .bar.open:nth-child(2) {
    transform: rotate(-45deg) translate(5px, -5px);
  }

  #header-cta {
    display: none;
  }

  .text-block {
    padding: 2rem 2rem;
  }

  .two-column-with-image {
    display: flex;
    flex-direction: column;
    height: auto;
  }

  .testimonials-block {
    display: flex;
    margin: 0 auto;
  }
}

@media screen and (max-width: 1279px) {
  .App {
    padding: 0;
  }      

  .form-section {
    width: 90%;
  }

  .form-row label {
    text-align: left;
    padding-right: 0;
  }
}

@media screen and (min-width: 1280px) {
  .App {
    padding: 0;
  }      
  
}